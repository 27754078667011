<template>
  <div class="mNews">
    <div class="new-img">
      <div class="new-head">
         <h3>河小象</h3>
          <p>少 儿 书 法 美 育</p>
      </div>
    </div>
    <div class="news-list">
      <div class="news-box" v-for="(item,index) in list" :key="index" @click="goNews(item.newUrl)">
        <img :src="item.newImg" class="newImg" />
        <div class="medium-msg">
          <h3>{{item.newTitle}}</h3>
          <div class="new-msg"><p>{{item.newInfo}}</p></div>
          <div class="medium-info">
            <button>
              查看详情
              <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/new-btn.png" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: []
    }
  },
  created(){
     this.getData(),
     this.statistics()

  },
   methods: {
    goNews(url) {
      window.location.href = url
    },
    getData(){
       this.axios.get('https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/news.json').then(res=>{
           this.list=res.data.list
         })
    },
    statistics(name){
      let arr=window.location.href.split('?')[1]
      if(arr!=undefined){
         if(arr.includes('isWinxin')){
           console.log(333)
          window._czc.push(['_trackEvent', '通过扫码进入新闻页', '访问数','扫码']);
        }
      }    
    }
  }
}
</script>
<style lang="scss" scoped>
.mNews {
  background: #F6F6F9;
  .new-img {
    height: 7.893rem;
    background: #979797;
    background: url('https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/mNew-banner.png');
    background-size: 100% 100%;
    color: #00234D;
    font-family:PingFangSC;
    .new-head{
      padding: .467rem .893rem;
      text-align: left;
      h3{
        font-size: .747rem;
        position: relative;
        &::after{
          content:'';
          width: 1.253rem;
          height: .067rem;
          background: #1295FF;
          position: absolute;
          left: 0;
          bottom:- .267rem;
        }
      }
      p{
        font-size: .373rem;
        margin-top: .5rem;
      }
    }
  
  }
  .news-list {
    margin: .533rem 0 .427rem;
    .news-box {
      padding: 0.333rem;
      display: flex;
      align-items: center;
      margin-bottom: .267rem;
      background: #fff;
      .newImg {
        width: 3.333rem;
        height: 3.333rem;
        margin-right: 0.52rem;
      }
      .medium-msg {
        color: #444444;
        flex: 1;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-height: 3.33rem;
        h3 {
          font-size: 0.4rem;
          font-family: PingFangSC;
          font-weight: bold;
          margin-bottom: 0.053rem;
          text-align: left;
           display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .new-msg{
          flex:1;
           p {
          font-size: 0.267rem;
          text-align: left;
           display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
          min-height: 1rem;
        }
        }
        .medium-info {
          display: flex;
          align-items: center;
          justify-content: end;
          color: #787878;
          font-size: .267rem;
          line-height: .4rem;
          margin-top: .3rem;
          button {
            color: #ff7041;
            display: flex;
            align-items: center;
            font-size: .267rem;
            img {
              width:.267rem;
              height: .267rem;
            }
          }
        }
      }
    }
  }
}
</style>
